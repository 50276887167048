.nested.dropdown {
   &:hover>.dropdown-menu {
      display: block;
   }

   .dropdown-menu {
      top: -15px;
      margin-left: -10em;
   }

   .dropdown-trigger {
      a::after {
         content: '>';
         display: none;
      }
   }

   // &:hover > .dropdown-trigger {
   //     a::before {
   //       content: '>';
   //       display: block;
   //       margin-right: 5px;
   //     }
   //   }
}

.site-dropdown .dropdown-menu a.dropdown-item:hover {
   color: #6fc047;
}

nav.navbar li.nav-item+.dropdown .dropdown-toggle {
   background: none;
   color: black !important;
   font-size: 15px;
   border: none;
   padding: 0;
}

nav.navbar li.nav-item+.dropdown .dropdown-toggle:focus {
   box-shadow: none !important;
}

nav.navbar li.nav-item+.dropdown .dropdown-toggle:active {
   background: none;
   border: none;
   color: #000;
}

nav.navbar li.nav-item+.dropdown .dropdown-toggle::after {
   border: none;
}

nav.navbar li.nav-item+.dropdown .dropdown-toggle+.dropdown-menu {
   margin-top: 0;
}

nav.navbar li.nav-item+.dropdown .dropdown-toggle+.dropdown-menu .dropdown-item:hover {
   background: #5cb648 !important;
   color: #fff;
   transition: 0.2s all;
}

button.policyDropdown-container.dropdown-toggle.btn.btn-secondary {
   background: none;
   color: black;
   font-size: 15px;
   border: none;
   padding: 0;
}

button.policyDropdown-container.dropdown-toggle.btn.btn-secondary::after {
   display: none;
}

span.policyDropdownmenu {
   display: block;
   width: 100%;
   padding: 0.25rem 1.5rem;
   clear: both;
   font-weight: 400;
   color: #212529;
   text-align: inherit;
   white-space: nowrap;
   background-color: transparent;
   border: 0;
   cursor: pointer;
}

span.policyDropdownmenu:hover {
   background: #5cb648;
   color: #fff;
   transition: 0.2s all;
}

.policyDropdown-main {
   .dropdown-menu {
      transform: translate3d(0px, 25px, 0px) !important;
   }
}

.login-modal {
   .modal-content .form-group input {
      box-shadow: none;
   }
}

.promo_section {
   padding: 12px 24px;

   .promo_banner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .gift_content {
         display: flex;
         gap: 10px;

         img {
            height: 32px;
            width: 32px;
         }

         h3 {
            margin: 0;
            font-size: 18px;
            font-weight: 800;
         }

         p {
            margin: 0;
            font-size: 14px;
         }

      }

      .button_close {
         display: flex;
         align-items: center;
         flex-wrap: wrap;
         gap: 15px;

         button {
            padding: 6px 24px;
            border: none;
            background-color: #ccc;
            // width: 150px;
            // height: 34px;
            border-radius: 24px;
            font-size: 18px;
            font-weight: 600;
            transition: all 200ms ease-in-out;
         }
      }
   }
}

@media screen and (max-width:992px) {


   .promo_section {
      padding: 12px 0px;
   }
}

@media screen and (max-width:480px) {
   .promo_section .promo_banner .button_close {
      display: inline-flex;
      width: 320px;
   }
}