.countdown-container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  gap            : 10px;


}

.countdown-box {
  position  : relative;
  text-align: center;
}

.countdown-value {
  font-size      : 21px;
  font-weight    : bold;
  background     : white;
  color          : #000;
  display        : flex;
  align-items    : center;
  justify-content: center;
  padding        : 0px 15px;
  border-radius  : 10px;
  font-family    : Arial, sans-serif;
  box-shadow     : 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.countdown-label {
  font-size     : 12px;
  font-weight   : bold;
  color         : #fff;
  text-transform: uppercase;
}

.expired {
  color      : red;
  font-size  : 14px;
  font-weight: bold;
  text-align : center;
}