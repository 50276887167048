.login-modal{
    .modal-backdrop.fade.show{
        background-color: rgba(0, 0, 0, 0.8) !important;
     }
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    .modal-content{
        width: 470px !important;
    }
   
}
.logsign-error{
    color: #d02424;
    display: flex;
    justify-content: center;
}