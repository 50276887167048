@media screen and (max-width: 767px) {
    .header .navbar-light .navbar-nav .nav-link {
        color: #000 !important;
    }
    .header .navbar-light .navbar-nav .nav-link:hover {
        color: #6fc047 !important;
    }
    .header .navbar-light .navbar-nav li.nav-item.demo-but a {
        color: #fff !important;
    }
    .banner-section {
        height: 100%;
        background: transparent;
        padding-top: 20px;
    }
    .header {
        position: relative;
    }
    .banner-right {
        text-align: center;
        transform: translate(0px, 0px);
    }
    .banner-con h2 {
        font-size: 26px;
    }
    .banner-con img {
        width: 48px;
        height: 48px;
    }
    .banner-con h6 {
        font-size: 19px;
    }
    .banner-con p {
        line-height: 24px;
        padding-left: 0;
        margin: 0;
    }
    .header .container {
        flex-wrap: wrap;
    }
    .site-logo img {
        /* filter: brightness(0.6) invert(1);*/
    }
    .header .container {
        flex-wrap: wrap;
    }
    section.cart-page,
    div.checkout-page {
        padding-top: 25px;
    }
    button.navbar-toggler {
        position: absolute;
        top: -31px;
        right: -19px;
        outline: none;
        padding: 2px 5px;
    }
    button.navbar-toggler span.navbar-toggler-icon {
        background-size: 79% 100%;
    }
    div#navbarNavDropdown {
        background: #fff;
        z-index: 99;
        padding: 20px 11px;
        border-radius: 8px;
        position: absolute;
        top: 0;
        width: 100%;
    }
    .link-but a {
        margin-left: 3px;
        font-size: 12px;
    }
    .link-but a:hover {
        padding: 8px 29px 8px 0 !important;
    }
    .link-but a:hover svg {
        left: 90px;
    }
    .banner-con {
        margin-bottom: 24px;
    }
    .link-but {
        justify-content: center;
        margin-bottom: 12px;
    }
    .user-friendly {
        padding: 35px 0;
    }
    .dms {
        text-align: center;
    }
    .dms h2:before,
    .why-cnt h2:before,
    .f-right-title h2:before,
    .doc-title h2:before {
        display: none;
    }
    .dms h2 {
        font-size: 20px;
        line-height: 28px;
    }
    .dms-group {
        width: 100%;
        padding-right: 0;
    }
    .t-flex {
        flex-wrap: wrap;
    }
    .dms-righ img {
        width: 100%;
    }
    .why-img {
        position: relative;
        left: 0;
    }
    .why-img img {
        width: 100%;
    }
    .why-cnt {
        text-align: center;
    }
    .why-cnt h6 {
        font-size: 14px;
    }
    .why-cnt h2 {
        font-size: 25px;
    }
    .why-right {
        padding-top: 50px;
    }
    .featurs {
        margin-top: 4em;
    }
    .featurs-title h2 {
        font-size: 25px;
    }
    .featurs-title h6 {
        font-size: 14px;
    }
    .featres-list {
        width: 100%;
        margin-top: 33px;
    }
    .features-group span {
        margin: 0 auto;
    }
    .features-group {
        flex-wrap: wrap;
        text-align: center;
    }
    .feature-content {
        margin-top: 16px;
    }
    .features-group:hover {
        border-radius: 10px;
        border-left-color: #fe8719;
    }
    .ex-img {
        position: relative;
        left: 0;
    }
    .explore .container {
        flex-wrap: wrap;
    }
    .ex-img img {
        width: 100%;
    }
    .f-right-title h2 {
        font-size: 25px;
    }
    .explore ul.slick-dots {
        width: 100%;
        bottom: -37px;
    }
    .vertical-sec {
        margin: 0;
        flex-wrap: wrap;
        text-align: center;
    }
    .vertical-sec span {
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .vertical-cnt h6 {
        font-size: 14px;
    }
    .vertical-cnt p {
        line-height: 22px;
    }
    .doc-title h2 {
        font-size: 25px;
    }
    .doc-title h6 {
        font-size: 14px;
    }
    .doc-price-box {
        flex-wrap: wrap;
        margin-bottom: 26px;
    }
    .doc-price {
        width: 100%;
    }
    .doc-price-img {
        width: 100%;
    }
    .doc-price-cnt {
        width: 100%;
        padding: 20px 20px 20px 20px;
    }
    .doc-price-total {
        width: 100%;
        border-left: 0px solid #e4e4e4;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .testimonial .container {
        flex-wrap: wrap;
    }
    .testi-left {
        width: 100%;
        padding-right: 0;
        padding-top: 0;
        text-align: center;
    }
    .testi-sec {
        padding: 20px;
    }
    .testi-right {
        width: 100%;
        margin-top: 12px;
    }
    .testi-sec:before {
        top: 4px;
        left: 5px;
    }
    .testi-sec:after {
        right: 0;
    }
    .testi-left h2 {
        font-size: 25px;
    }
    .testi-left p {
        line-height: 22px;
    }
    .slider-buttons button {
        height: 36px;
    }
    .faq-title h2 {
        font-size: 25px;
    }
    div#accordion {
        width: 100%;
    }
    div#accordion .card .card-header button {
        font-size: 13px;
    }
    div#accordion .card .card-header button svg {
        margin-right: -9px;
    }
    div#accordion .card-body {
        line-height: 22px;
    }
    .lets-cnt p {
        width: 100%;
    }
    .lets-talk {
        padding: 0px 0;
        background: transparent;
    }
    .lets-cnt h2 {
        font-size: 25px;
    }
    .footer-sec {
        flex-wrap: wrap;
    }
    .first-part p {
        font-size: 13px;
        width: 100%;
        line-height: 24px;
    }
    .three-part ul li {
        display: flex;
    }
    .three-part ul li span {
        margin-right: 10px;
    }
    .footer-bottom .container {
        flex-wrap: wrap;
    }
    .footer-bottom ul li a {
        margin: 0 4px;
    }
    .copy-w {
        margin-bottom: 14px;
    }
    .featurs-title h2:before {
        display: none;
    }
    /*------------ Policies & Procedures ---------------*/
    .policies-page,
    div.your-order-page {
        padding-top: 20px;
    }
    .your-order-page li.nav-item {
        margin: 0 1px;
    }
    .filter-header,
    .filter-sec {
        flex-wrap: wrap;
    }
    div.page-header h3 {
        font-size: 22px;
    }
    .filter-sec div {
        width: 100%;
        margin: 7px 0;
    }
    .filter-sec div select {
        width: 100%;
    }
    /*------------ End Policies & Procedures ---------------*/
    /*------------ inner-pages ---------------*/
    .detail-page {
        padding-top: 15px;
    }
    .details-cnt {
        flex-wrap: wrap;
    }
    .details-right-img ul {
        flex-wrap: wrap;
    }
    .details-right-img ul li {
        width: 100%;
        margin-bottom: 10px;
    }
    .detail-price ul li.detail-amount {
        width: auto;
    }
    .details-right-img {
        padding-top: 20px;
    }
    .details-but button {
        margin-right: 6px;
    }
    .det-doc-list ul li {
        padding: 9px 10px;
    }
    .cart-table {
        overflow: auto;
    }
    .cart-table td img {
        height: 100px;
        width: 100px;
    }
    .table-button {
        text-align: center;
    }
    .cart-total {
        margin: 0 auto;
        margin-top: 20px;
    }
    .checkout {
        flex-wrap: wrap;
    }
    div.check-left,
    div.check-right {
        width: 100%;
    }
    div.c-form-group.one-line {
        width: 100%;
    }
    div.c-form-group.one-line:last-child {
        margin-left: 0;
    }
    .check-right {
        padding-left: 0;
    }
    .order-buttons {
        flex-wrap: wrap;
    }
    .your-order-page ul#ordertab li.nav-item .nav-link {
        width: 91px;
        font-size: 13px;
    }
    ul#ordertab {
        margin-bottom: 10px;
    }
    .your-order-table {
        overflow-y: auto;
    }
    div.your-order-table table {
        width: 1092px;
    }
    div.my-acc-form .my-form {
        width: 100%;
        padding-right: 0;
    }
    div.myaccount-form {
        width: 100%;
    }
    div.myaccount,
    section.signin {
        padding-top: 20px;
    }
    /*------------ End inner-pages ---------------*/
    /*------------ Signin ---------------*/
    .sign-form {
        width: 100%;
    }
    /*------------ End Signin ---------------*/
    /*------------ SignUp ---------------*/
    div.signup-form,
    div.sp-form {
        width: 100%;
    }
    section.signup {
        padding-top: 10px;
    }
    /*------------ End SignUp ---------------*/
    .cart-table table {
        width: 508px;
    }
    .c-form-group input {
        height: 37px;
        outline: none;
    }
    .filter-sec {
        margin: 23px auto 0;
    }
    .order-buttons .nav-filter select {
        font-size: 11px;
    }
    /*------------ company my account ---------------*/
    .company-form,
    .c-group,
    .c-group input {
        width: 100%;
    }
    /*------------ End company my account ---------------*/
    div#navbarNavDropdown {
        top: 22px;
        box-shadow: 0px 0px 10px #cecece;
    }
    .navbar-light .navbar-toggler {
        border-color: rgb(251 244 244 / 84%);
    }
    button.navbar-toggler span.navbar-toggler-icon {
        filter: brightness(1) invert(1);
    }
    .header {
        padding-bottom: 0;
    }
    section.policies-page,
    section.detail-page {
        padding-top: 20px;
    }
    div.filter-sec div {
        margin-bottom: 10px;
    }
    .details-right-img p {
        text-align: justify;
    }
    .details-right-img ul li b {
        font-size: 11px;
    }
    /* .details-right-img ul li:last-child {
    margin-left: 2px;
} */
    .page-header {
        text-align: center;
        width: 100%;
    }
    div.policies-parts .col-lg-4 {
        padding: 0px;
    }
    div.polic-sec {
        margin: 0 0px;
    }
    div.polic-cnt-head h4 a,
    div.polic-cnt-head b {
        font-size: 15px;
    }
    .testi-right .slick-prev,
    .testi-right .slick-next {
        display: none !important;
    }
    .testi-part h4 {
        font-size: 20px;
    }
    div#accordion .card-body {
        text-align: justify;
    }
    .dms-group img {
        margin-right: 11px;
    }
    .link-but a {
        height: 36px;
    }
    .explore ul.slick-dots li.slick-active button {
        background: #579f34;
    }
    .explore ul.slick-dots li.slick-active button:before {
        color: #579f34 !important;
    }
    section.feature-doc {
        margin-top: 30px;
    }
    .doc-price-cnt h3 a {
        font-size: 16px;
    }
    section.why-choose {
        margin-bottom: 2em !important;
    }
    .polic-cnt .polic-left {
        width: 50%;
    }
    span.polic-cnt-head h4 a {
        font-size: 15px;
    }
    span.polic-cnt-head b {
        font-size: 15px;
    }
    .polic-cnt-head h4 {
        padding-right: 10px;
    }
    div.details-right-img ul li b {
        line-height: normal;
        margin-bottom: 10px;
        font-size: 11px;
    }
    div.details-right-img ul li {
        width: 28%;
        list-style: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .details-right-img span.detail-price ul li {
        width: 100%;
        margin-bottom: 13px;
        margin-left: 0;
        justify-content: center;
    }
    .detail-price ul li span p {
        width: 100%;
        text-align: center;
    }
    .detail-price ul li span b {
        width: 100%;
        text-align: center;
    }
    .detail-price ul li span {
        flex-wrap: wrap;
    }
    .detail-price ul li span b {
        padding-top: 11px;
        margin-bottom: 0;
    }
    .details-but button {
        font-size: 13px;
    }
    .cart-header h2 {
        font-size: 22px;
    }
    .sys-features-part h4 {
        font-size: 19px;
    }
    .feature-grp {
        padding-left: 12px;
    }
    div.sign-form {
        width: 100%;
    }
    div.c-form-group.one-line {
        width: 100%;
    }
    div.c-form-group.one-line:last-child {
        margin-left: 0;
    }
    .site-dropdown .dropdown-menu:before {
        display: none;
    }
    .site-dropdown .dropdown-menu.show a {
        color: #000 !important;
    }
}

@media screen and (min-width: 568px) and (max-width:767px) {
    .doc-price-box .doc-price-cnt {
        width: 60%;
    }
    .doc-price-box .doc-price-img {
        width: 20%;
    }
    .doc-price-box .doc-price-total {
        width: 20%;
    }
    .doc-price-box .doc-price-total button {
        padding: 8px 16px;
    }
    .doc-price-img img {
        object-fit: contain;
    }
    .dms-righ img,
    .why-img img {
        max-width: 69%;
        margin: 0 auto;
    }
    .dms-righ,
    .why-img {
        text-align: center;
    }
    .policies-parts .col-lg-4 {
        -ms-flex: 0 0 48%;
        flex: 0 0 48%;
        max-width: 48%;
        margin: 6px 4px;
    }
    .filter-sec div {
        width: 36%;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px) {
    .banner-section {
        height: 100%;
        padding-bottom: 64px;
    }
    .banner-right {
        transform: translate(0px, 12%);
    }
    .banner-con h2 {
        font-size: 31px;
    }
    .banner-con h6 {
        font-size: 24px;
    }
    .banner-con {
        margin-bottom: 14px;
        margin-top: 30px;
    }
    .banner-con p {
        padding-left: 64px;
    }
    .head-right {
        z-index: 9;
    }
    button.navbar-toggler {
        position: absolute;
        top: -9px;
        right: 0;
        outline: none;
    }
    div#navbarNavDropdown {
        position: absolute;
        top: 63px;
        width: 100%;
        background: #fff;
        padding: 11px;
    }
    .banner-con img {
        width: 49px;
        height: 46px;
    }
    .link-but a:hover {
        padding: 8px 31px 8px 0 !important;
    }
    .dms-righ img {
        width: 300px;
    }
    .dms h2 {
        font-size: 25px;
        line-height: 35px;
    }
    .user-friendly .t-flex .col-xs-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .user-friendly .t-flex {
        flex-wrap: wrap;
    }
    .dms-righ {
        text-align: center;
    }
    .why-img {
        position: relative;
        left: 0;
        text-align: center;
    }
    .why-choose .t-flex {
        flex-wrap: wrap;
    }
    .why-cnt {
        text-align: left;
    }
    .why-right {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .why-img img {
        width: 300px;
    }
    .why-left {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .why-cnt h2 {
        font-size: 25px;
    }
    .why-cnt h2:before {
        right: auto;
    }
    .link-but {
        justify-content: flex-start;
    }
    .why-cnt .link-but a {
        margin-left: 0;
    }
    .featurs {
        margin-top: 76px;
    }
    .ex-img {
        position: relative;
        left: 0;
    }
    .ex-img img {
        width: 300px;
    }
    .ex-left {
        -ms-flex: 0 0 48.333333%;
        flex: 0 0 48.333333%;
        max-width: 48.333333%;
        display: none;
    }
    .ex-right {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .f-right-title h2 {
        font-size: 25px;
    }
    .f-right-title h2:before {
        width: 260px;
        bottom: -13px;
        right: auto;
    }
    .doc-price {
        width: 100%;
    }
    .doc-price-cnt {
        padding: 35px 16px 35px 16px;
    }
    .testi-left h2 {
        font-size: 25px;
    }
    .testi-left {
        padding-right: 24px;
    }
    .testi-sec {
        padding: 66px 15px 48px 15px;
    }
    div#accordion {
        width: 85%;
    }
    div#accordion .card .card-header button {
        font-size: 14px;
    }
    .lets-talk {
        padding: 70px 0;
    }
    .first-part p {
        width: 100%;
    }
    .three-part ul li {
        display: flex;
    }
    /*------------ Policies & Procedures ---------------*/
    .filter-sec div {
        margin: 0 2px;
    }
    .filter-header {
        flex-wrap: wrap;
    }
    .filter-sec {
        width: 100%;
    }
    .page-header h3 {
        font-size: 25px;
    }
    .policies-parts {
        margin-top: 12px;
    }
    .polic-cnt div p {
        font-size: 9px;
    }
    .polic-cnt p {
        font-size: 12px;
    }
    .polic-cnt-head h4 {
        font-size: 13px;
    }
    /*------------ End Policies & Procedures ---------------*/
    /*------------ inner-pages ---------------*/
    .details-cnt {
        flex-wrap: wrap;
    }
    .details-left-img,
    .details-right-img {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        margin-bottom: 19px;
    }
    .details-left-img img {
        width: auto;
    }
    .details-right-img {
        text-align: left;
    }
    .c-form-group.one-line {
        width: 46%;
    }
    .your-order-table {
        overflow-y: auto;
    }
    .your-order-table table {
        width: 1079px;
    }
    /*------------ End inner-pages ---------------*/
    /*------------  company my account ---------------*/
    ul#companytab li.nav-item .nav-link {
        padding: 9px;
        width: 172px;
        font-size: 9px;
    }
    .company-form {
        width: 100%;
    }
    /*------------ End company my account ---------------*/
    section.header {
        background: transparent;
    }
    .testi-right .slick-next,
    .testi-right .slick-prev {
        display: none !important;
    }
    .footer-sec .first-part {
        -ms-flex: 0 0 30.666667%;
        flex: 0 0 30.666667%;
        max-width: 30.666667%;
    }
    .footer-sec .two-part {
        -ms-flex: 0 0 39.666667%;
        flex: 0 0 39.666667%;
        max-width: 39.666667%;
    }
    .footer-sec .two-part ul {
        margin-right: 18px;
    }
    div.policies-parts .col-lg-4 {
        flex: 0 0 48%;
        max-width: 48%;
        margin: 6px 0px;
        padding: 0px;
    }
    span.polic-cnt-head h4 a {
        font-size: 15px;
    }
    span.polic-cnt-head b {
        font-size: 16px;
    }
    div.details-left-img img {
        width: auto;
    }
}

@media screen and (min-width: 1024px) and (max-width:1200px) {
    .banner-right {
        transform: translate(0px, 18%);
    }
    .dms h2 {
        font-size: 32px;
        line-height: 40px;
    }
    .dms-group {
        width: 49%;
        padding-right: 12px;
    }
    .dms-righ img {
        width: 100%;
    }
    .dms-group p {
        line-height: 23px;
    }
    .featurs {
        margin-top: 7em;
    }
    .ex-img img {
        width: 100%;
    }
    .ex-img {
        left: -98px;
    }
    .f-right-title h2 {
        font-size: 34px;
    }
    .vertical-sec {
        margin-right: 27px;
    }
    .vertical-item {
        margin-top: 4px;
    }
    .testi-left h2 {
        font-size: 29px;
    }
    .banner-section {
        height: 100%;
        padding-bottom: 120px;
    }
    .banner-con h2 {
        font-size: 41px;
    }
    .banner-con {
        margin-bottom: 20px;
        margin-top: 22px;
    }
    .home-page-client .row {
        justify-content: center;
    }
    /*------------  Policies & Procedures ---------------*/
    .polic-cnt div p {
        font-size: 9px;
    }
    .polic-cnt-head h4,
    .polic-cnt p,
    .polic-cnt-head b {
        font-size: 13px;
    }
    /*------------ End Policies & Procedures ---------------*/
    /*------------ inner-pages ---------------*/
    .c-form-group.one-line {
        width: 47%;
    }
    .your-order-table td a {
        padding: 6px 6px;
        font-size: 13px;
    }
    /*------------ End inner-pages ---------------*/
    /*------------  End company my account ---------------*/
    ul#companytab li.nav-item .nav-link {
        width: 232px;
        font-size: 12px;
    }
    .company-form {
        width: 100%;
    }
    /*------------  company my account ---------------*/
    section.why-choose {
        margin-bottom: 2em !important;
    }
    .doc-price-cnt h3 a {
        font-size: 18px;
    }
    .first-part p {
        width: 100%;
    }
    .footer-sec .first-part {
        -ms-flex: 0 0 35.666667%;
        flex: 0 0 35.666667%;
        max-width: 35.666667%;
    }
    .footer-sec .two-part {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 35.333333%;
        max-width: 35.333333%;
    }
    .footer-sec .two-part ul {
        margin-right: 40px;
    }
    .footer-sec .three-part {
        -ms-flex: 0 0 30%;
        flex: 0 0 30%;
        max-width: 30%;
    }
    .lets-talk {
        padding: 108px 0;
    }
    .why-img img {
        max-width: 87%;
    }
    .why-cnt h2 {
        font-size: 34px;
    }
    .detail-price ul li.detail-amount {
        font-size: 21px;
    }
    .policies-parts .col-lg-4 {
        padding: 0px;
    }
    span.polic-cnt-head h4 a {
        font-size: 16px;
    }
    span.polic-cnt-head b {
        font-size: 16px;
    }
    nav.navbar li.nav-item {
        margin: 0 6px;
    }
    .header .navbar-light .navbar-nav .nav-link {
        padding: 0;
    }
    .site-dropdown .dropdown-menu.show {
        left: -47px;
        top: 39px;
    }
    .site-dropdown .dropdown-menu:before {
        left: 82px;
    }
    .c-form-group.one-line:last-child {
        margin-left: 18px;
    }
    .pay-group.exp-date.c-form-group.one-line.mr-4 {
        margin-right: 18px !important;
    }
}