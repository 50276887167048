.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.left-side {
    width: 270px !important;
    float: left;
    height: 100vh;
    background: #fff;
    z-index: 99;
    position: relative;
}

.left_dash span {
    color: #fff;
}

.col-lg-3 {
    padding-right: 11px;
    padding-left: 11px;
}

.left-side ul li {
    color: #fff;
    list-style: none;
    margin: 13px 0;
    padding: 5px 16px;
}

.left-dev ul {
    padding: 0;
}

.right-side {
    width: 76%;
    float: left;
    margin-top: 49px;
    background: #fff !important;
    padding: 20px 20px 20px;
    border-radius: 10px;
    box-shadow: 0px 3px 4px #d5d5d5;
    margin-left: 24px;
}

.dashboard_rights {
    background: #f5f5f591;
    min-height: 125px;
    display: flex;
    border-radius: 5px;
    padding: 14px;
    flex-wrap: wrap;
}


/*-------------- Animation ------------------*/
p.number-load {
    background-image: linear-gradient(to left, #cbcbcb52, #c1c1c103 50%, #8f8f8f00);
    background-size: 606px 10%;
    background-position-x: 0;
    animation: spinBackground 1s linear infinite;
    width: 100%;
    display: inline-block;
    color: transparent !important;
    border-radius: 5px;
    font-size: 16px;
    margin: 10px 0;
}

span.text-animation {
    background-image: linear-gradient(to left, #cbcbcb52, #c1c1c103 50%, #8f8f8f00);
    background-size: 606px 10%;
    background-position-x: 0;
    animation: spinBackground 1s linear infinite;
    width: 100%;
    display: inline-block;
    color: transparent !important;
    border-radius: 5px;
    font-size: 13px;
    margin-bottom: 6px;
}

span.img-load {
    width: 73%;
    background-image: linear-gradient(to left, #cbcbcb52, #c1c1c103 50%, #8f8f8f00);
    background-size: 606px 10%;
    background-position-x: 0;
    animation: spinBackground 1s linear infinite;
    display: inline-block;
    color: transparent !important;
    border-radius: 5px;
    font-size: 27px;
    height: 50px;
    width: 50px;
    margin-left: 15px;
}

.small-img-ldr {
    background-image: linear-gradient(to left, #cbcbcb52, #c1c1c103 50%, #8f8f8f00);
    background-size: 606px 10%;
    background-position-x: 0;
    animation: spinBackground 1s linear infinite;
    display: inline-block;
    color: transparent !important;
    border-radius: 5px;
    width: 28px;
    height: 28px;
    float: left;
    margin-right: 10px;
}

.menu-load {
    background-image: linear-gradient(to left, #cbcbcb52, #c1c1c103 50%, #8f8f8f00);
    background-size: 606px 10%;
    background-position-x: 0;
    animation: spinBackground 1s linear infinite;
    display: inline-block;
    color: transparent !important;
    border-radius: 5px;
    width: 80%;
    height: 28px;
}

@keyframes spinBackground {
    100% {
        background-position-x: 300px;
    }
}

@keyframes loader {
    0% {
        width: 0%;
        left: 0;
        right: 0
    }

    50% {
        width: 100%;
        left: 0;
        right: 0
    }

    99% {
        width: 0%;
        left: 100%;
        right: 0
    }
}

@-webkit-keyframes loader {
    0% {
        width: 0%;
        left: 0;
        right: 0
    }

    50% {
        width: 100%;
        left: 0;
        right: 0
    }

    99% {
        width: 0%;
        left: 100%;
        right: 0
    }
}

@keyframes loader {
    0% {
        width: 0%;
        left: 0;
        right: 0
    }

    50% {
        width: 100%;
        left: 0;
        right: 0
    }

    99% {
        width: 0%;
        left: 100%;
        right: 0
    }
}

@-webkit-keyframes loader {
    0% {
        width: 0%;
        left: 0;
        right: 0
    }

    50% {
        width: 100%;
        left: 0;
        right: 0
    }

    99% {
        width: 0%;
        left: 100%;
        right: 0
    }
}


.right-side-box {
    width: 15% !important;
    float: left;
    margin: 0 8px;
}

.right-side-table {
    width: 76%;
    float: left;
    margin-top: 60px;
}

.head-left-ani {
    background-image: linear-gradient(to left, #9e9e9e08, #a9a9a938 50%, #88888800);
    background-size: 606px 10%;
    background-position-x: 0;
    animation: spinBackground 1s linear infinite;
    display: inline-block;
    color: transparent !important;
    border-radius: 5px;
    width: 200px;
    height: 28px;
    margin-left: 20px;
}

.head-right-ani {
    float: right;
    background-image: linear-gradient(to left, #9e9e9e08, #a9a9a938 50%, #88888800);
    background-size: 606px 10%;
    background-position-x: 0;
    animation: spinBackground 1s linear infinite;
    display: inline-block;
    color: transparent !important;
    border-radius: 5px;
    width: 100px;
    height: 28px;
    margin-left: 20px;
}

.tab_ani th,
.tab_ani td {
    background-image: linear-gradient(to left, #9e9e9e08, #a9a9a938 50%, #88888800);
    background-size: 606px 10%;
    background-position-x: 0;
    animation: spinBackground 1s linear infinite;
    display: inline-block;
    color: transparent !important;
    border-radius: 5px;
    width: 100px;
    height: 28px;
    margin: 7px 0;
    margin-left: 40px;
}

.tab_ani {
    width: 100%;
    margin-top: 15px;
}

.tab_ani tr {
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6 !important;
}

.left_dash {
    width: 100%;
}

.bottom-box-ani {
    margin-top: 20px;
    display: inline-block;
    width: 100%;
}

.bottom-box-ani .right-side-box {
    width: 23.23% !important;
}

.left_dash p.number-load:first-child {
    padding: 23px 0px;
}

li.long-logo .menu-load {
    width: 98%;
    height: 80px;
}


/* Full page loader */
/* To center the spinner*/
.pos-center {
    position: fixed;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
}

.full-page-loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #5cb648;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    background: transparent;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}